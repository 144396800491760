html,
body,
#root {
  margin: 0;
  height: 100vh;
}

.page-schedule-meals ul {
  margin: 0;
  width: 100%;
  list-style: none;
}

.page-schedule-meals .ant-badge.ant-badge-status {
  display: flex;
  align-items: baseline;
}

.page-schedule-meals .ant-badge.ant-badge-status .ant-badge-status-dot {
  flex-shrink: 0;
}

.page-schedule-meals .ant-badge.ant-badge-status .ant-badge-status-text {
  font-size: 12px;
}

.page-schedule-meals ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.page-schedule-meals .ant-list-items {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
}

.page-schedule-meals .ant-list-item {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  min-height: 130px;
  flex-shrink: 0;
}

.page-schedule-meals .ant-list-items .ant-list-item:hover {
  background: rgba(0, 0, 0, 0.04);
  transition: background 0.3s;
  cursor: pointer;
}

.page-schedule-meals .button-to-maps {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.page-schedule-meals .ant-divider {
  position: sticky;
  left: 0;
}

.page-schedule-meals .ant-card {
  width: max-content;
}

.page-schedule-meals .ant-card .ant-card-body {
  display: flex;
  flex-direction: column;
}

.page-schedule-meals .location-tag-title {
  position: sticky;
  left: 24px;
  display: inline-block;
  max-width: 40vw;
}

.page-schedule-meals .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #f00 !important;
}

.page-schedule-meals .mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #f00 !important;
}

.page-schedule-meals .mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #f00 !important;
}

.page-schedule-meals .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #f00 !important;
}

.page-schedule-meals .mapboxgl-popup-content {
  padding: 0;
  text-align: center;
  background: #f00;
  color: #fff;
  box-shadow: 0px 20px 20px #000;
  width: 24px;
}

.page-schedule-meals .marker-popup {
  margin: 0;
}

.page-schedule-meals .ant-card-actions li > span {
  margin: 0 auto;
}

.driver-form-container
  .ant-picker-cell.ant-picker-cell-selected
  .ant-picker-calendar-date {
  color: #000 !important;
  background: transparent !important;
}

.driver-form-container
  .ant-picker-calendar-date:has(
    .ant-picker-calendar-date-content .driver-leave-cell
  ) {
  color: #fff !important;
  background: #f00 !important;
}

.driver-form-container .ant-picker-cell:not(.ant-picker-cell-in-view) {
  opacity: 0;
}
